import React from "react";
import { Container, Grid } from "@material-ui/core/";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer() {
  let date = new Date().getFullYear();
  return (
    <>
      <footer>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <h3 className="footer_h">Courses</h3>
              <div className="footer_link">
                <Link className="footer_link_a" to={`/courses/class/${"9-10"}`}>
                  Class 9-10 - CBSE/ICSE/State
                </Link>
                <Link className="footer_link_a" to={`/courses/class/${"11-12"}`}>
                  Class 11-12 - CBSE/ICSE/State
                </Link>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <h3 className="footer_h">Quick Links</h3>
              <div className="footer_link">
                <a
                  className="footer_link_a"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.gharpeshiksha.com/"
                >
                  GharPeShiksha
                </a>

                <a
                  className="footer_link_a"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.gharpeshiksha.com/OnlineCourseStudentLanding.html"
                >
                  Tutoring
                </a>
                <a
                  className="footer_link_a"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.gharpeshiksha.com/OnlineCourseStudentLanding.html"
                >
                  GharPeShiksha Online Classes
                </a>
                <a
                  className="footer_link_a"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.gharpeshiksha.com/about-us.jsp"
                >
                  About Us
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>

      <p className="copyright">
        Copyright © {date} GharPeShiksha.com ( Tenali Education Academy Pvt.
        Ltd.) | All Rights Reserved
      </p>
    </>
  );
}

export default Footer;
