import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core/";
import "./ProductInfo.css";
import { ProductA } from "./Product";
import { useStateValue } from "../../StateProvider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import CartDrawer from "../cart/cartDrawer";
import FaqComponent from "../faq/faq";
import { settings } from "../SlickSettings/settings";
import BookFreeClassModal from "../faq/faqModal";

function ProductInfo(props) {
  const { location, match } = props;
  const [, dispatch] = useStateValue();
  const [state, setState] = useState({
    // 'Hindi':false,
    // 'English': false,
    // 'Maths': false,
    // 'Science': false,
    // 'Social Science': false,
  });
  const history = useHistory();
  const [data, setdata] = useState({});
  const [data1, setdata1] = useState([]);
  const [sub, setsub] = useState([`${match.params.subject}`]);
  const [val, setval] = useState({
    num: 0,
  });

  const [dval, dsetval] = useState({
    num: 0,
  });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetch = async () => {
      const res = await axios.post(
        `https://api.gharpeshiksha.com/OnlineCoursePricingDetails/getCourse`,
        null,
        {
          signal: signal,

          params: {
            id: match.params.id,
          },
        }
      );

      setdata(res.data);

      const res1 = await axios.post(
        "https://api.gharpeshiksha.com/OnlineCoursePricingDetails/getCourseDetail"
      );
      setdata1(res1.data.OnlineCoursePriceDetails);
    };
    fetch();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return function cleanup() {
      abortController.abort();
    };
  }, [match.params.id]);

  const addToBasket = (e) => {
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: match.params.id,
        title: data.Title,
        image: location.state.Image,
        price: location.state.DiscountedPrice + val.num,
        dis: location.state.OriginalPrice + dval.num,
        sub: sub,
      },
    });

    if (e) {
      history.push("/courses/cart");
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    if (event.target.checked) {
      setval({ ...val, num: Number(event.target.value) + val.num });
      setsub([...sub, event.target.name]);
      dsetval({ ...dval, num: Number(event.target.id) + dval.num });
    } else {
      dsetval({ ...dval, num: dval.num - Number(event.target.id) });
      setval({ ...val, num: val.num - Number(event.target.value) });
      let x = sub.indexOf(event.target.name);

      sub.splice(x, 1);
    }
  };

  return (
    <section className="section_product">
      {data && (
        <Container
          maxWidth="lg"
          style={{ alignItems: "center", display: "flex" }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <div className="fix">
                {data.Image ? (
                  <img
                    className="image_info image-card"
                    src={data.Image && data.Image}
                    alt="product"
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{ color: "steelblue" }} />
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <h2 className="product_h">{data?.Title}</h2>
              {sub?.length ? (
                <div className="addon">
                  <h3>Add On : </h3>
                  {sub?.map((item, id) => (
                    <span key={id} className="small">
                      {item}
                    </span>
                  ))}
                </div>
              ) : null}
              <h2 className="product_price">
                Rs. {data.DiscountedPrice?.toFixed(2)} &nbsp;{" "}
                <del style={{ fontSize: "30px" }}>
                  Rs. {data.OriginalPrice?.toFixed(2)}{" "}
                </del>
              </h2>
              <div className="space">
                <CartDrawer addToBasket={addToBasket} />
                <Button
                  style={{
                    padding: "8px 4px",
                    width: "50%",
                    margin: "4px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => addToBasket(true)}
                >
                  BUY IT NOW
                </Button>
              </div>

              <div className="space1">
                <div>
                  <h3>Add Subject</h3>
                </div>

                <FormGroup row>
                  <FormControlLabel
                    className="myinput"
                    key={data.CourseId}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        value={data.DiscountedPrice}
                        id={data.OriginalPrice?.toString()}
                        name={data.Subject}
                        color="primary"
                        defaultChecked={true}
                      />
                    }
                    label={`${data.Subject} Rs. ${data.DiscountedPrice}`}
                  />
                </FormGroup>
              </div>

              <div className="product_margin">
                <h3>Description</h3>
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: data.Description }}
                ></p>
              </div>
            </Grid>
            <Container maxWidth="md">
              <Typography style={{ textAlign: "center" }} variant="h4">
                FAQ?
              </Typography>
              <FaqComponent />
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Typography variant="h6">
                  Any Question Still Unanswered? Call Us{" "}
                  <a href="tel:+91 7065-950-98">7065-950-985</a>
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <BookFreeClassModal call={true} />
              </div>
            </Container>
            <Grid className="spacing1" item xs={12}>
              <h2
                style={{
                  textAlign: "center",
                  letterSpacing: "3px",
                  color: "#3e3e3e",
                }}
              >
                YOU MAY ALSO LIKE
              </h2>
            </Grid>

            <div
              style={{
                width: "100%",
                padding: "10px",
              }}
            >
              {data1 && (
                <Slider {...settings}>
                  {data1?.map((d, i) => (
                    <ProductA key={i} data={d} />
                  ))}
                </Slider>
              )}
            </div>
          </Grid>
        </Container>
      )}
    </section>
  );
}

export default ProductInfo;
