import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { Card, Divider, IconButton, Typography } from "@material-ui/core";
import { useStateValue } from "../../StateProvider";
import { getBasketTotal } from "../../reducer";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "550px",
  },
  fullList: {
    width: "auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "20px 30px",
  },
  cartCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "90%",
    marginBottom: "15px",
    padding: "10px",
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    width: "100px",
    height: "100px",
    marginRight: "15px",
  },
}));

export default function CartDrawer({ addToBasket }) {
  const classes = useStyles();
  const [, dispatch] = useStateValue();
  const [{ basket }] = useStateValue();
  const [state, setState] = useState({
    right: false,
  });

  const removeFromBasket = (id) => {
    // remove the item from the basket
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id,
    });
  };

  const total = getBasketTotal(basket);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    addToBasket(false);
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.header}>
        <Typography variant="h4">Cart</Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CancelIcon />
        </IconButton>
      </div>
      <Divider style={{ margin: "auto", maxWidth: "90%" }} />
      <div className={classes.header}>
        <Typography>{basket.length} items</Typography>
        <Typography className={classes.header} variant="h5">
          <b>Subtotal </b>
          <Typography
            style={{
              color: "steelblue",
              marginLeft: "10px",
              fontSize: "30px",
            }}
          >
            &#x20b9;{total}
          </Typography>
        </Typography>
      </div>
      <Divider
        style={{ margin: "auto", maxWidth: "90%", marginBottom: "15px" }}
      />
      <div className={classes.div}>
        {basket.map((item, i) => {
          return (
            <Card className={classes.cartCard} key={i}>
              <img className={classes.image} src={item.image} alt="" />
              <div>
                Price
                <Typography
                  style={{
                    textDecoration: "line-through",
                    fontSize: "11px",
                    color: "red",
                  }}
                >
                  &#x20b9;{item.dis}
                </Typography>
                &#x20b9;{item.price}
              </div>
              <div
                style={{
                  maxWidth: "50%",
                }}
              >
                <Typography>{item.title}</Typography>
              </div>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => removeFromBasket(item.id)}
                variant="outlined"
                color="secondary"
              >
                <CancelIcon />
              </IconButton>
            </Card>
          );
        })}
      </div>
      {basket.length > 0 && (
        <div
          style={{
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link style={{ textDecoration: "none" }} to={"/courses/cart"}>
            <Button color="primary" variant="contained">
              Buy Now <ArrowForwardIcon style={{ marginLeft: "10px" }} />
            </Button>
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <div style={{ width: "48%", marginRight: "2%" }}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            style={{
              padding: "8px 4px",
              margin: "4px",
            }}
            variant="outlined"
            color="primary"
            fullWidth
          >
            ADD TO Cart
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
// addToBasket(false)
