import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { faqData } from "./faqdata";
import BookFreeClassModal from "./faqModal";

export default function FaqComponent() {
  return (
    <div>
      {faqData.map((doc, i) => {
        return <FaqSubComponent doc={doc} key={i} />;
      })}
    </div>
  );
}

const FaqSubComponent = ({ doc }) => {
  const [open, setOpen] = useState(false);

  return (
    <Accordion style={{ marginBottom: "20px", borderRadius: "10px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          padding: "10px",
          background: open ? "steelblue" : "rgb(176, 216, 248)",
          borderRadius: "10px ",
        }}
        onClick={() => setOpen(!open)}
      >
        <Typography style={{ fontSize: "20px", color: open && "white" }}>
          {doc.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: "16px 40px",
          display: "flex",
          flexDirection: "column",
          background: "#E8F1F5",
        }}
      >
        <Typography style={{ fontSize: "18px", color: "steelblue" }}>
          {doc.desc}
        </Typography>
        <BookFreeClassModal />
      </AccordionDetails>
    </Accordion>
  );
};
