export const faqData = [
  {
    title: "How will the doubts be resolved?",
    desc: "You will attend live classes for that specific chapter or raise your queries in live doubt sessions. You can ask the teacher directly in interactive live class.",
  },
  {
    title: "How will it help me with school homework?",
    desc: "We are following the School Curriculum only. After attending live classes / watching video classes, you will understand the chapter in detail. Once your doubts have been cleared and you have learned your concepts. You can also post your doubts to teachers.",
  },
  {
    title: "How will parents know the performance of their child?",
    desc: "Our teachers regularly monitor the progress of every child and timely send the progress reports to parents as well.",
  },
  {
    title: "How is it better than nearby tuition?",
    desc: "We have handpicked the India Best expert Teachers on our platform. Our live classes also gets recorded that also gives you the flexibility to attend class at your time. It does not require you to travel to the tuition centre and dress well. Solve your doubts instantly by connecting to Best Teacher.",
  },
  {
    title: "How will you provide notes to us?",
    desc: "The study notes of the respective chapters will be provided by the teacher after the completion of the class.",
  },
  {
    title: "Will I get a demo class just to check the platform?",
    desc: "Yes, you can take the demo class to check the quality of our services.",
  },
];
