import React from 'react'
import {BrowserRouter as Router,Switch,Route} from "react-router-dom"
import Home from "./component/home/Home";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import ProductInfo from "./component/product/ProductInfo";
import Cart from "./component/cart/Cart";
import CoursePage from "./component/CoursePage";
const App2 = () => {
  return <Router>
         <Header/>
         <Switch>
             <Route exact path={"/courses"} component={Home}   />
             <Route exact path={"/courses/cart"} render={(props) => (
              <>
                <Cart {...props} />
              </>
            )} />
             <Route exact path="/courses/class/:id?" component={CoursePage}  />
             <Route
            exact
            path="/courses/:subject/:board/:course/:id"
            render={(props) => (
              <>
                <ProductInfo {...props} key={window.location.pathname} />
              </>
            )}
          />

         </Switch>
         <Footer/>

  </Router>
}

export default App2