import React, { forwardRef, useState } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  Slide,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import logo from "../../images/logo.png";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookFreeClassModal({ call }) {
  const [open, setOpen] = useState(false);
  const [cls, setClass] = React.useState("");

  const handleChange = (event) => {
    setClass(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        style={{
          marginTop: "12px",
          padding: "16px 34px",
          backgroundImage:
            "linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed)",
          boxShadow: "0 4px 15px 0 rgb(65 132 234 / 15%)",
          textDecoration: "none",
          color: "white",
          maxWidth: "300px",
          textAlign: "center",
          borderRadius: "5px",
          border:"none",
          outline:"none",
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: call ? "20px" : "15px",
        }}
        onClick={handleClickOpen}
        data-toggle="modal"
        className="btn-hover btn color-9 "
      >
        {call ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Start a free Trial{" "}
            {call && <ArrowForwardIosIcon style={{ marginLeft: "10px" }} />}
          </div>
        ) : (
          "Book Free Demo Class"
        )}
      </button>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        style={{
          backdropFilter: "blur(10px)",
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{ padding: "10px 10px 0 0" }}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            padding: "20px 0",
          }}
        >
          <img width="80px" height="90px" src={logo} alt="GharPeShiksha" />
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", color: "steelblue" }}
          >
            GharPeShiksha
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              textAlign: "center",
              letterSpacing: "1.5px",
              marginBottom: "20px",
            }}
          >
            JOIN GHARPESHIKSHA LEARNING PROGRAM
          </Typography>
        </div>
        <Divider />
        <DialogContent
          style={{
            padding: "20px",
          }}
        >
          <form
            style={{
              padding: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <TextField
                required
                type="text"
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </div>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <FormControl fullWidth variant="outlined">
                <InputLabel>Class</InputLabel>
                <Select
                  required
                  type="select"
                  value={cls}
                  onChange={handleChange}
                  label="Class"
                  fullWidth
                >
                  <MenuItem value="VI">VI</MenuItem>
                  <MenuItem value="VII">VII</MenuItem>
                  <MenuItem value="VIII">VIII</MenuItem>
                  <MenuItem value="IX">IX</MenuItem>
                  <MenuItem value="X">X</MenuItem>
                  <MenuItem value="XI">XI</MenuItem>
                  <MenuItem value="XII">XII</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <TextField
                required
                label="Phone Number"
                variant="outlined"
                fullWidth
              />
            </div>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <TextField
                required
                type="email"
                label="Enter Email"
                variant="outlined"
                fullWidth
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ maxWidth: "250px" }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Next
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
