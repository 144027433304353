import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Tooltip, useMediaQuery } from "@material-ui/core/";
import { fade, makeStyles } from "@material-ui/core/styles";
import "./Header.css";
import logo from "../../images/logo.png";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
// import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { useStateValue } from "../../StateProvider";
import { useHistory } from "react-router-dom";
import CallIcon from "@material-ui/icons/Call";
import MobileMenu from "../MobileMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 100,
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minHeight: 56,
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "26%",
    background: "#a2a2a2!important",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header({ setSearch }) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const [{ basket }] = useStateValue();

  const history = useHistory();
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "white" }}>
        <Toolbar className={classes.toolbar}>
          {matches ? <MobileMenu /> : null}
          <Link to="/courses" style={{ textDecoration: "none" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="head_nav"
                style={{ maxWidth: "75px" }}
                src={logo}
                alt="logo"
              />
              <Typography className="title" variant="h5" noWrap>
                GharPeShiksha
              </Typography>
            </div>
          </Link>
          <div className="nav_link_top">
            <div className="top_nav">
              <Link className="footer_link_a" to={`/courses/class/${"9-10"}`}>
                <span className="nav-link">Class 9-10 - CBSE/ICSE/State</span>
              </Link>
            </div>

            <div className="top_nav">
              <Link className="footer_link_a" to={`/courses/class/${"11-12"}`}>
                <span className="nav-link">Class 11-12 - CBSE/ICSE/State</span>
              </Link>
            </div>
          </div>
          <div>
            {matches ? null : (
              <p style={{ color: "black" }}>Talk to our Counselor now</p>
            )}
            <a
              style={{
                textDecoration: "none",
                color: "steelblue",
                fontSize: "20px",
              }}
              href="tel:+91 7065565735"
            >
              {matches ? null : "+91 7065565735"}
              <Tooltip
                style={{ fontSize: "15px" }}
                title="+91 7065565735"
                arrow
              >
                <IconButton
                  style={{
                    background: "steelblue",
                    marginLeft: "10px",
                    width: matches && "30px",
                    height: matches && "30px",
                  }}
                >
                  <CallIcon fontSize="small" style={{ color: "white" }} />
                </IconButton>
              </Tooltip>
            </a>
          </div>
          <div>
            <IconButton
              onClick={() => history.push("/courses/cart")}
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={basket.length} color="secondary">
                <ShoppingBasketOutlinedIcon className="basket" />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
