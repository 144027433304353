import React from "react";
import { Container, Grid, Button } from "@material-ui/core/";
import "./Cart.css";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";
import CheckoutProduct from "../checkout/CheckoutProduct";
import { useStateValue } from "../../StateProvider";
import Subtotal from "../product/Subtotal";
import { useHistory } from "react-router-dom";
import CustomizedDialogs from "../form/Form";

function Cart() {
  const history = useHistory();
  const [{ basket }] = useStateValue();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className="cart_sec">
      {!basket?.length ? (
        <>
          {" "}
          <h1 className="big_f">Your Cart</h1>
          <p>Your cart is currently empty.</p>
          <div>
            <Button
              onClick={() => history.push("/courses")}
              style={{ padding: "12px 40px", marginTop: "12px" }}
              variant="contained"
              color="primary"
            >
              Continue Shopping &nbsp; <ArrowRightAltOutlinedIcon />
            </Button>
          </div>
        </>
      ) : (
        <div>
          <h1 className="big_f">Your Cart</h1>

          <Container style={{ marginTop: "70px" }} maxWidth="lg">
            <Grid container spacing={2}>
              {basket.map((item) => (
                <CheckoutProduct
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  dis={item.dis}
                  sub={item.sub}
                  quantity={item.quantity}
                />
              ))}
            </Grid>

            <div className="side_total">
              <Subtotal onClick={handleClickOpen} />
            </div>
          </Container>
        </div>
      )}
      <CustomizedDialogs get open={open} close={handleClose} />
    </section>
  );
}

export default Cart;
