import React, { useState, useEffect, useMemo } from "react";
import { Container, Divider, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Product } from "../product/Product";
import CoursesTabs from "./CoursesTabs";

const CoursePage = () => {
  const params = useParams();
  // const [, setAge] = useState("");
  const ID = params.id.split("-")[0];
  const [courses, setCourses] = useState([]);
  const classList = useMemo(() => {
    return [
      "Class 9th-10th",
      "Class 11th-12th Science",
      "Class 11th-12th Commerce",
      "Class 11th-12th Humanites",
    ];
  }, []);
  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const res = await axios.post(
        "https://api.gharpeshiksha.com/OnlineCoursePricingDetails/getCourses"
      );
      if (mounted) {
        const data = [];
        if (ID === "9") {
          for (let key in res.data) {
            if (key === `For Class 9th-10th`) {
              const obj = res.data[key];
              data.push(obj);
            }
          }
        } else {
          data.push([]);
          for (let i = 1; i < classList.length; i++) {
            for (var key in res.data) {
              if (key === `For ${classList[i]}`) {
                const obj = res.data[key];
                data.push(obj);
              }
            }
          }
        }
        setCourses(data);
      }
    };

    fetch();

    return () => {
      mounted = false;
    };
  }, [ID, classList]);

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  return (
    <Container>
      <Typography
        style={{
          padding: "50px 0",
          textAlign: "center",
        }}
        variant="h3"
      >
        CBSE/ICSE/State Board
      </Typography>
      <Divider />
      {/* <div style={{ padding: "20px" }}>
        <div>
          <div>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>All Products</InputLabel>
              <Select value={age} onChange={handleChange}>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>7 products</div>
      </div> */}
      <section>
        {courses.length && ID === "9" ? (
          <Container
            style={{
              marginBottom: "50px",
              alignItems: "center",
              justifyContent: "center",
            }}
            maxWidth="lg"
          >
            <Typography
              style={{
                marginBottom: "20px",
              }}
              variant="h4"
            >
              <p
                style={{
                  borderBottom: "2px solid black",
                  width: "fit-content",
                }}
              >
                {classList[0]}
              </p>
            </Typography>
            <Grid
              style={{
                alignContent: "center",
                justifyItems: "center",
              }}
              container
              spacing={2}
            >
              {courses[0].map((doc, i) => (
                <Product key={i} data={doc} />
              ))}
            </Grid>
          </Container>
        ) : (
          <CoursesTabs courses={courses} classList={classList} />
        )}
      </section>
    </Container>
  );
};

export default CoursePage;
