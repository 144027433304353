import { Container } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";
import ReviewsCard from "./reviewsCard";
import student3 from "../../images/student3.webp";
import reema from "../../images/reema.webp";
import "./style.css";

const ReviewsCardDetails = [
  {
    img: reema,
    name: "Ms. Reema Karwal",
    review:
      "I must admit that GharPeShiksha's services have impressed me a lot. Ever since the schools have been closed, I was looking to enroll my child to an online education portal. But the cost of other portals have always Demotivated me and I had to postpone the idea. But GharPeShiksha's affordable plans have made it easier for me.",
    title: "Parent",
  },
  {
    img: student3,
    name: "Mr. Harleen Singh",
    review:
      "In the age of the Internet when there are millions of website offering online education services, GharPeShiksha stands out for me. Its expert faculty has made the concepts simpler and fun to learn. I really enjoy learning with gharpeshiksha. ",
    title: "Student",
  },
  {
    img: "https://www.gharpeshiksha.com/newImg/parent2.jpg",
    name: "Ms. Preeti Uppal",
    review:
      "My earlier experience with other online education portals have not been satisfactory. I just thought to give GharPeShiksha a try and check out their services.But to my surprise, It turned out to be one of my best decisions. Now my child has resumed studying and he has almost finished the syllabus before time. ",
    title: "Parent",
  },
];

const Reviews = () => {
  const settings = {
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    prevArrow: null,
    nextArrow: null,
  };
  return (
    <Container maxWidth="md">
      <div className="reviews_card_container">
        <div className="ReviewsCard__wrapper">
          <Slider {...settings}>
            {ReviewsCardDetails.map((doc, i) => {
              return (
                <ReviewsCard
                  review={doc.review}
                  img={doc.img}
                  name={doc.name}
                  key={i}
                  title={doc.title}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </Container>
  );
};

export default Reviews;
