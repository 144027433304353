import React from "react";

const ReviewsCard = ({ img, name, review, title }) => {
  return (
    <div className="review__card">
      <div className="review__card_left">
        <div className="review__card_img">
          <img src={img} alt="" />
        </div>
        <p>&nbsp;</p>
        <h4 style={{ fontWeight: "600px" }}>{name}</h4>
        <p>{title}</p>
      </div>
      <div className="review__card_right">
        <div className="quotes_review_right">
          <img
            align="left"
            alt=""
            src="https://www.gharpeshiksha.com/newImg/quote_right.webp"
          />
        </div>
        <div className="review_card_desc">
          <p>{review}</p>
        </div>
        <div className="quotes_review_left">
          <img
            alt=""
            src="https://www.gharpeshiksha.com/newImg/quote_left.webp"
            align="right"
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewsCard;
