import React from "react";
import { Grid,makeStyles } from "@material-ui/core/";
import "./Product.css";
import { Link ,useLocation} from "react-router-dom";
const style=makeStyles((theme)=>({
  // product card 
  productCard:{
    display:"flex",
    alignContent:"center",
    justifyContent:"center",cursor:"pointer",
    // border:"2px solid orange",

  },
  // image box
  ImageBox:{
    display:"flex",
    alignContent:"center",
    width:"100%",
    justifyContent:"center",
    // border:"2px solid green",
  },
  // image:{}
  Image:{
    width:"90%",
    objectFit:"cover"
  }
}));
export function Product({ data }) {
  // using this function for styling 
  const Classes=style();
  const {
    CourseId,
    DiscountedPrice,
    Course,
    Board,
    Description,
    OriginalPrice,
    Subject,
    Title,
    Image,
  } = data;
  return (
    // style={{ cursor: "pointer",margin:"0px",    border:"2px solid orange",display:"flex",alignItems:"center",justifyContent:"center" }}
    <Grid className={Classes.productCard}  item xs={8} sm={3}>
      <Link
        to={{
          pathname: `/courses/${Subject.replace(/\s+/g, "-")}/${Board.replace(
            /\s+/g,
            "-"
          )}/${Course.replace(/\s+/g, "-")}/${CourseId}`,
          state: {
            OriginalPrice,
            Image,
            DiscountedPrice,
            Subject,
            Description,
            Board,
            Course,
          },
          
        }}
      >
        {/* style={{ width: "100%" ,border:"2px solid orange",display:"flex",alignItems:"center",justifyContent:"center"}} */}
        <div className={Classes.ImageBox} >
          <img className="product_image" src={Image} alt="product" />
        </div>
        <div className="card__title">
          <h3 style={{ fontSize: "18px" }}> {Title}</h3>

          <span className="price-item" data-regular-price="">
            Rs {DiscountedPrice}
          </span>

          <span className="price-item" data-regular-price="">
            <del> Rs {OriginalPrice}</del>
          </span>
        </div>
      </Link>
    </Grid>
  );
}

export function ProductA({ data }) {
  const {
    CourseId,
    Image,
    Course,
    Title,
    Board,
    DiscountedPrice,
    OriginalPrice,
    Subject,
    Description,
  } = data;

  return (
    <div className="product__card">
      <Link
        to={{
          pathname: `/courses/${Subject.replace(/\s+/g, "-")}/${Board.replace(
            /\s+/g,
            "-"
          )}/${Course.replace(/\s+/g, "-")}/${CourseId}`,
          state: {
            OriginalPrice,
            Image,
            DiscountedPrice,
            Subject,
            Description,
            Board,
            Course,
          },
        }}
      >
        <div style={{ cursor: "pointer",padding:"0 4px" }}>
          <img
            className="product_image"
            style={{ maxWidth: "275px" }}
            // style={{ maxWidth: "262px" }}

            src={Image}
            alt="product"
          />
          <div style={{ maxWidth: "244px" }} className="card__title">
            <h3 style={{ fontSize: "18px" }}>{Title}</h3>

            <span className="price-item" data-regular-price="">
              {" "}
              Rs {DiscountedPrice}{" "}
            </span>

            <span className="price-item" data-regular-price="">
              <del>Rs {OriginalPrice}</del>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}
