import React, { useState, useEffect } from "react";
import "./Home.css";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core/";
import { Product } from "../product/Product";
import axios from "axios";
import Header from "../header/Header";
import PlatFormComp from "../Platform";
import Reviews from "../reviews";
import SEOComp from "./Seo";

function Home() {
  const [data, setdata] = useState([]);
  const [, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const classList = [
    "Class 9th-10th",
    "Class 11th-12th Science",
    "Class 11th-12th Commerce",
    "Class 11th-12th Humanites",
  ];
  useEffect(() => {
    const fetch = async () => {
      await setLoading(() => true);
      const res = await axios.post(
        "https://api.gharpeshiksha.com/OnlineCoursePricingDetails/getCourses"
      );
      const data = [];

      for (let i = 0; i < classList.length; i++) {
        for (var key in res.data) {
          if (key === `For ${classList[i]}`) {
            const obj = res.data[key];
            data.push(obj);
          }
        }
      }
      setdata(data);
      await setLoading(() => false);
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <Header setSearch={setSearch} /> */}
      <div className="section_top"></div>
      <section style={{ background: "#edf8fe", padding: "50px 0 50px 0" }}>
        <PlatFormComp />
      </section>
      <section className="section">
        {loading && (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {data.length &&
          data.map((cls, i) => {
            if (cls.length === 0) {
              return null;
            }
            return (
              <Container
                style={{
                  marginBottom: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={i}
                maxWidth="lg"
              >
                <Typography
                  style={{
                    marginBottom: "20px",
                  }}
                  variant="h4"
                >
                  <p
                    style={{
                      // border:2px solid orange",
                      marginLeft:"6px",
                      // borderBottom: "2px solid black",
                      width: "fit-content",
                    }}
                  >
                    {classList[i]}
                  </p>
                </Typography>
                <Grid
                  style={{
                    alignContent: "center",
                    justifyItems: "center",
                  }}
                  container
                  spacing={2}
                >
                  {cls.map((doc, i) => (
                    <Product key={i} data={doc} />
                  ))}
                </Grid>
              </Container>
            );
          })}
      </section>
      <section style={{ background: "#EDF8FE", paddingBottom: "50px" }}>
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontWeight: "bold",
          }}
        >
          What Our Student & Parents Says about us
        </Typography>
        <Reviews />
      </section>
      <section>
        {/* <SEOComp /> */}
      </section>
    </>
  );
}

export default Home;
