import React from "react";
import { Grid, IconButton } from "@material-ui/core/";
import { useStateValue } from "../../StateProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  align: {
    maxWidth: "15em",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20em",
      margin: "10px 0 6px",
    },
  },
}));

function CheckoutProduct({
  id,
  title,
  image,
  price,
  quantity,
  sub,
  add,
  minus,
  dis,
}) {
  const classes = useStyles();
  const [, dispatch] = useStateValue();

  const removeFromBasket = () => {
    // remove the item from the basket
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });
  };

  //subtract qt action
  //  const subtractQuantity=(id)=>{
  //     dispatch({
  //         type: 'SUB_QUANTITY',
  //         id: id,
  //     })
  // }
  //add qt action
  //  const addQuantity=(id,price)=>{
  //     dispatch({
  //         type: 'ADD_QUANTITY',
  //        id:{ id: id,
  //         price:price}
  //     })
  // }

  const cus = {
    display: "block",
    background: "#757575",
    color: "white",
    borderRadius: "6px",
    padding: " 4px 7px",
    margin: "6px 4px",
  };

  return (
    <Grid
      style={{ padding: "20px 24px" }}
      container
      justify="center"
      className="cart_b"
      item
      xs={12}
    >
      <div className="cart_space" style={{ maxWidth: "12em" }}>
        <img
          style={{ maxWidth: "150px", maxHeight: "100px" }}
          src={image}
          alt="product"
        />
      </div>
      <div style={{ maxWidth: "33em", width: "33em" }}>
        <Link to={"/courses/cart"} style={{ textDecoration: "none" }}>
          <h3> {title} </h3>
        </Link>
        <IconButton
          onClick={removeFromBasket}
          variant="outlined"
          color="secondary"
        >
          <CancelIcon />
        </IconButton>
      </div>

      <div className={classes.align}>
        {sub.length ? (
          <>
            <span>
              <b> Add On :</b>
            </span>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {sub.map((item, id) => (
                <b key={id} style={cus}>
                  {" "}
                  {item}{" "}
                </b>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <div style={{ marginLeft: "auto" }}>
        <p className="cart_p"> Rs. {price.toFixed(2)} </p>
        <p style={{ fontSize: "20px" }} className="cart_p">
          <del> Rs. {dis.toFixed(2)}</del>{" "}
        </p>
      </div>
    </Grid>
  );
}

export default CheckoutProduct;
