import React from "react";
import live from "../../images/platform/live.png";
import subject1 from "../../images/platform/subject1.png";
import full from "../../images/platform/full.png";
import book from "../../images/platform/book.png";
import note from "../../images/platform/note.png";
import mcq from "../../images/platform/mcq.png";
import inter from "../../images/platform/inter.png";
import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import "./style.css";

const data = [
  {
    title: "Interactive Classes",
    img: live,
  },
  {
    title: "Conceptual Videos",
    img: subject1,
  },
  {
    title: "Full Course Detailed Videos",
    img: full,
  },
  {
    title: "NCERT Solutions",
    img: book,
  },
  {
    title: "Revision Notes",
    img: note,
  },
  {
    title: "Self MCQ Test",
    img: mcq,
  },
  {
    title: "Interactive Doubt Live Classes",
    img: inter,
  },
  {
    title: "Instant Chat for Doubt solving",
    img: inter,
  },
];

const PlatFormComp = () => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "50px",
        }}
      >
        <p
          style={{
            fontSize: matches ? "25px" : "40px",
            borderBottom: "2px solid black",
            width: "fit-content",
          }}
        >
          Our Platform Features
        </p>
      </div>
      <Grid spacing={2} container>
        {data.map((doc, i) => {
          return (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={6}
              sm={4}
              md={3}
              key={i}
              item
            >
              <div className="platform__cards">
                <div>
                  <img
                    width="70px"
                    height="70px"
                    src={doc.img}
                    alt={doc.title}
                  />
                </div>
                <Typography style={{ maxWidth: "90%", textAlign: "center" }}>
                  {doc.title}
                </Typography>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PlatFormComp;
